import { removeCookie } from 'bv';

import { loadCurrentLimits, loadLimitOptions } from 'DepositLimits/duck';
import api from 'DepositLimits/api';
import { showFetchError, limitModals } from 'CashierCommon/helpers';
import {
  closeModal,
  updateDepositLimitInit,
  updateDepositLimitComplete,
} from './deposit_store';
import { isLimitMandatory } from '../selectors/deposit_limit';

export const setDepositLimit = (amount, period) => (dispatch, getState) => {
  dispatch(updateDepositLimitInit());

  const { limitOptions } = getState().depositLimits;

  return api[limitOptions.api].setNewLimit(amount, period)
    .then((response) => {
      if (response.success) {
        dispatch(closeModal(limitModals.DEPOSIT_LIMIT));
        dispatch(loadCurrentLimits());
        if (isLimitMandatory(getState())) {
          dispatch(loadLimitOptions());
        }

        removeCookie('desktopNewRegistration');
      } else {
        showFetchError();
      }
    }).catch(() => showFetchError()).finally(() => {
      dispatch(updateDepositLimitComplete());
    });
};
